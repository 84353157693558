const makeWPCall = async (input, wpAjaxObj) => {
  // eslint-disable-line
  const data = new FormData(); // eslint-disable-line

  data.append("action", "autocompleteAjaxCallback");
  data.append("nonce", wpAjaxObj.ajax_nonce);
  data.append("search_term", input);

  try {
    const response = await fetch(wpAjaxObj.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });

    return await response.json();
  } catch (error) {
    return error;
    // console.log("error ", error);
  }
};

const getResults = async (input, wpAjaxObj) => {
  // make a fetch call to WP
  try {
    const response = await makeWPCall(input, wpAjaxObj);

    return await response;
  } catch (error) {
    return error;
  }
};

const displayResults = (data, resultsContainer) => {
  const container = resultsContainer;
  container.style.display = "block";
  container.innerHTML = "";

  data.forEach((result) => {
    container.innerHTML += `<li class="search-item">${result.label}</li>`;
  });
};

const resultsContainerClick = (resultsContainer, searchInput) => {
  const container = resultsContainer;
  container.onclick = (e) => {
    const input = searchInput.querySelector("input[id='search']");
    const setValue = e.target.innerText;
    input.value = setValue;
    container.innerHTML = "";

    const searchButton = document.querySelector('input[class="search-icon"]'); // eslint-disable-line

    searchButton.click();
  };
};

const searchInputOnInput = (resultsContainer, searchInput, wpAjaxObj) => {
  const container = resultsContainer;
  const search = searchInput;
  search.oninput = (e) => {
    const userInput = e.target.value;

    container.innerHTML = "";

    if (userInput.length < 3) {
      return;
    }

    getResults(userInput, wpAjaxObj).then((data) => {
      displayResults(data, container);
    });
  };
};

window.addEventListener("load", () => {
  // eslint-disable-line
  const searchInput = document.getElementById("search-form"); // eslint-disable-line
  const wpAjaxObj = autocomplete_search_ajax; // eslint-disable-line

  if (!searchInput || !wpAjaxObj || Object.keys(wpAjaxObj).length === 0) {
    return;
  }

  const resultsContainer = document.getElementById("search-results"); // eslint-disable-line

  searchInputOnInput(resultsContainer, searchInput, wpAjaxObj);

  resultsContainerClick(resultsContainer, searchInput);
});
